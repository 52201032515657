<!--
  Control Panel Page
-->
<template>
  <div>
    <CRow>
      <CCol xl="12">
        <CForm @submit="submitFilterValues">
          <CCard>
            <CButton
              @click="cardCollapse = !cardCollapse"
              tag="button"
              color="link"
              block
              class="flex flex--space-between text-left shadow-none card-header"
            >
              <h5 class="m-0">Filtros</h5>
              <CIcon :name="`cil-chevron-${cardCollapse ? 'top' : 'bottom'}`" />
            </CButton>
            <CCollapse :show="cardCollapse">
              <CCardBody>
                <CRow>
                  <CCol xl="12">
                    <label class="filter-label">{{
                      messages.filters.area
                    }}</label>
                    <FiltersAreaCombo
                      :activeElements="activeItems"
                      v-show="showBools.FiltersAreaCombo"
                    />
                    <div v-if="areaError" class="error-message error-message">
                      {{ messages.validation.filterAreaCombo }}
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol xl="4">
                    <label class="filter-label">FY</label>
                    <FilterFY v-show="showBools.FilterFY" />
                  </CCol>
                  <CCol xl="4">
                    <label class="filter-label">{{
                      messages.filters.period
                    }}</label>
                    <FilterMonth v-show="showBools.FilterMonth"
                  /></CCol>
                  <CCol xl="4">
                    <label class="filter-label">{{
                      messages.filters.cumulativeOrNot
                    }}</label>
                    <FilterIsCumulative v-show="showBools.FilterIsCumulative" />
                  </CCol>
                </CRow>
              </CCardBody>
              <CCardFooter>
                <CButton type="submit" color="primary">Mostrar</CButton>
              </CCardFooter>
            </CCollapse>
          </CCard>
        </CForm>
      </CCol>
    </CRow>
    <CCard v-show="showResults">
      <CCardHeader>
        <span><strong>{{ messages.tables.results }}</strong> {{selectedArea}} {{ selectedArea && '-' }} {{ period }}</span>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol xl="6">
            <CCard
              v-if="invoiceNoCumulative.show"
              class="fadeIn anim-speed--1s"
            >
              <CCardHeader>{{
                messages.controlPanel.invoiceNoCumulative
              }}</CCardHeader>
              <CCardBody>
                <CChartLine
                  :datasets="invoiceNoCumulative.datasets"
                  :labels="invoiceNoCumulative.labels"
                />
              </CCardBody>
            </CCard>
            <CRow
              v-if="!invoiceNoCumulative.show"
              class="justify-content-center"
            >
              <Loader
                width="100px"
                height="100px"
                fill-color="#c2c2c2"
                cssClass="fadeIn anim-speed--1s"
              />
            </CRow>
          </CCol>
          <CCol xl="6">
            <CCard v-if="invoiceCumulative.show" class="fadeIn anim-speed--1s">
              <CCardHeader>{{
                messages.controlPanel.invoiceCumulative
              }}</CCardHeader>
              <CCardBody>
                <CChartBar
                  :datasets="invoiceCumulative.datasets"
                  :labels="invoiceCumulative.labels"
                />
              </CCardBody>
            </CCard>
            <CRow v-if="!invoiceCumulative.show" class="justify-content-center">
              <Loader
                width="100px"
                height="100px"
                fill-color="#c2c2c2"
                cssClass="fadeIn anim-speed--1s"
              />
            </CRow>
          </CCol>
        </CRow>
        <CRow>
          <CCol xl="4">
            <CCard
              v-if="showCompletionBlocks"
              class="product-results fadeIn anim-speed--1s"
            >
              <CCardHeader class="bg-custom-box">
                <strong>{{
                  messages.products.volumeBonus.toUpperCase()
                }}</strong>
              </CCardHeader>
              <CCardBody class="bg-custom-box">
                <RankingProgress
                  code="BV"
                  :value="completion.BV.value"
                  :rawValue="completion.BV.rawValue"
                  :concept="completion.BV.concept"
                  :criterion="completion.BV.criterion"
                  :progressBarValues="completion.BV.progressBarValues"
                />
              </CCardBody>
              <CCardHeader v-if="averageBlocks !== null" class="ranking"
                >Ranking</CCardHeader
              >
              <CCardBody v-if="averageBlocks !== null">
                <p v-if="showAverageBlocks >= 1">
                  <span class="product-results--desc"
                    >{{ averageBlocks.BV.country.description }}
                  </span>
                  <span class="product-results--value">{{
                    `${averageBlocks.BV.country.value} ${averageBlocks.BV.country.units}`
                  }}</span>
                </p>
                <p v-if="showAverageBlocks >= 2">
                  <span class="product-results--desc"
                    >{{ averageBlocks.BV.aspmRegional.description }}
                  </span>
                  <span class="product-results--value">{{
                    `${averageBlocks.BV.aspmRegional.value}  ${averageBlocks.BV.aspmRegional.units}`
                  }}</span>
                </p>
                <p v-if="showAverageBlocks === 3">
                  <span class="product-results--desc"
                    >{{ averageBlocks.BV.concession.description }}
                  </span>
                  <span class="product-results--value">{{
                    `${averageBlocks.BV.concession.value}  ${averageBlocks.BV.concession.units}`
                  }}</span>
                </p>
                <p v-if="showAverageBlocks === 3">
                  <span
                    >{{ averageBlocks.BV.concessionRanking.description }}
                  </span>
                  <span class="product-results--value">{{
                    `${averageBlocks.BV.concessionRanking.ranking} de ${averageBlocks.BV.concessionRanking.totalRanked}`
                  }}</span>
                </p>
              </CCardBody>
            </CCard>
            <CRow v-if="!showCompletionBlocks" class="justify-content-center">
              <Loader
                width="100px"
                height="100px"
                fill-color="#c2c2c2"
                cssClass="fadeIn anim-speed--1s"
              />
            </CRow>
          </CCol>

          <CCol xl="4">
            <CCard
              v-if="showCompletionBlocks"
              class="product-results fadeIn anim-speed--1s"
            >
              <CCardHeader class="bg-custom-box">
                <strong>{{ messages.products.oil.toUpperCase() }}</strong>
              </CCardHeader>
              <CCardBody class="bg-custom-box">
                <RankingProgress
                  code="T05"
                  :value="completion.T05.value"
                  :rawValue="completion.T05.rawValue"
                  :concept="completion.T05.concept"
                  :criterion="completion.T05.criterion"
                  :progressBarValues="completion.T05.progressBarValues"
                />
              </CCardBody>
              <CCardHeader v-if="averageBlocks !== null" class="ranking"
                >Ranking</CCardHeader
              >
              <CCardBody v-if="averageBlocks !== null">
                <p v-if="showAverageBlocks >= 1">
                  <span class="product-results--desc"
                    >{{ averageBlocks.T05.country.description }}
                  </span>
                  <span class="product-results--value">{{
                    `${averageBlocks.T05.country.value} ${averageBlocks.T05.country.units}`
                  }}</span>
                </p>
                <p v-if="showAverageBlocks >= 2">
                  <span class="product-results--desc"
                    >{{ averageBlocks.T05.aspmRegional.description }}
                  </span>
                  <span class="product-results--value">{{
                    `${averageBlocks.T05.aspmRegional.value}  ${averageBlocks.T05.aspmRegional.units}`
                  }}</span>
                </p>
                <p v-if="showAverageBlocks === 3">
                  <span class="product-results--desc"
                    >{{ averageBlocks.T05.concession.description }}
                  </span>
                  <span class="product-results--value">{{
                    `${averageBlocks.T05.concession.value}  ${averageBlocks.T05.concession.units}`
                  }}</span>
                </p>
                <p v-if="showAverageBlocks === 3">
                  <span
                    >{{ averageBlocks.T05.concessionRanking.description }}
                  </span>
                  <span class="product-results--value">{{
                    `${averageBlocks.T05.concessionRanking.ranking} de ${averageBlocks.T05.concessionRanking.totalRanked}`
                  }}</span>
                </p>
              </CCardBody>
            </CCard>
            <CRow v-if="!showCompletionBlocks" class="justify-content-center">
              <Loader
                width="100px"
                height="100px"
                fill-color="#c2c2c2"
                cssClass="fadeIn anim-speed--1s"
              />
            </CRow>
          </CCol>
          <CCol xl="4">
            <CCard
              v-if="showCompletionBlocks"
              class="product-results fadeIn anim-speed--1s"
            >
              <CCardHeader class="bg-custom-box">
                <strong>{{ messages.products.tyres.toUpperCase() }}</strong>
              </CCardHeader>
              <CCardBody class="bg-custom-box">
                <RankingProgress
                  code="T03"
                  :value="completion.T03.value"
                  :rawValue="completion.T03.rawValue"
                  :concept="completion.T03.concept"
                  :criterion="completion.T03.criterion"
                  :progressBarValues="completion.T03.progressBarValues"
                />
              </CCardBody>
              <CCardHeader v-if="averageBlocks !== null" class="ranking"
                >Ranking</CCardHeader
              >
              <CCardBody v-if="averageBlocks !== null">
                <p v-if="showAverageBlocks >= 1">
                  <span class="product-results--desc"
                    >{{ averageBlocks.T03.country.description }}
                  </span>
                  <span class="product-results--value">{{
                    `${averageBlocks.T03.country.value} ${averageBlocks.T03.country.units}`
                  }}</span>
                </p>
                <p v-if="showAverageBlocks >= 2">
                  <span class="product-results--desc"
                    >{{ averageBlocks.T03.aspmRegional.description }}
                  </span>
                  <span class="product-results--value">{{
                    `${averageBlocks.T03.aspmRegional.value}  ${averageBlocks.T03.aspmRegional.units}`
                  }}</span>
                </p>
                <p v-if="showAverageBlocks === 3">
                  <span class="product-results--desc"
                    >{{ averageBlocks.T03.concession.description }}
                  </span>
                  <span class="product-results--value">{{
                    `${averageBlocks.T03.concession.value}  ${averageBlocks.T03.concession.units}`
                  }}</span>
                </p>
                <p v-if="showAverageBlocks === 3">
                  <span
                    >{{ averageBlocks.T03.concessionRanking.description }}
                  </span>
                  <span class="product-results--value">{{
                    `${averageBlocks.T03.concessionRanking.ranking} de ${averageBlocks.T03.concessionRanking.totalRanked}`
                  }}</span>
                </p>
              </CCardBody>
            </CCard>
            <CRow v-if="!showCompletionBlocks" class="justify-content-center">
              <Loader
                width="100px"
                height="100px"
                fill-color="#c2c2c2"
                cssClass="fadeIn anim-speed--1s"
              />
            </CRow>
          </CCol>
          <CCol xl="4">
            <CCard
              v-if="showCompletionBlocks"
              class="product-results fadeIn anim-speed--1s"
            >
              <CCardHeader class="bg-custom-box">
                <strong>{{
                  messages.products.paintAndConsumables.toUpperCase()
                }}</strong>
              </CCardHeader>
              <CCardBody class="bg-custom-box">
                <RankingProgress
                  code="BPC"
                  :value="completion.BPC.value"
                  :rawValue="completion.BPC.rawValue"
                  :concept="completion.BPC.concept"
                  :criterion="completion.BPC.criterion"
                  :progressBarValues="completion.BPC.progressBarValues"
                />
              </CCardBody>
              <CCardHeader v-if="averageBlocks !== null" class="ranking"
                >Ranking</CCardHeader
              >
              <CCardBody v-if="averageBlocks !== null">
                <p v-if="showAverageBlocks >= 1">
                  <span class="product-results--desc"
                    >{{ averageBlocks.BPC.country.description }}
                  </span>
                  <span class="product-results--value">{{
                    `${averageBlocks.BPC.country.value} ${averageBlocks.BPC.country.units}`
                  }}</span>
                </p>
                <p v-if="showAverageBlocks >= 2">
                  <span class="product-results--desc"
                    >{{ averageBlocks.BPC.aspmRegional.description }}
                  </span>
                  <span class="product-results--value">{{
                    `${averageBlocks.BPC.aspmRegional.value}  ${averageBlocks.BPC.aspmRegional.units}`
                  }}</span>
                </p>
                <p v-if="showAverageBlocks === 3">
                  <span class="product-results--desc"
                    >{{ averageBlocks.BPC.concession.description }}
                  </span>
                  <span class="product-results--value">{{
                    `${averageBlocks.BPC.concession.value}  ${averageBlocks.BPC.concession.units}`
                  }}</span>
                </p>
                <p v-if="showAverageBlocks === 3">
                  <span
                    >{{ averageBlocks.BPC.concessionRanking.description }}
                  </span>
                  <span class="product-results--value">{{
                    `${averageBlocks.BPC.concessionRanking.ranking} de ${averageBlocks.BPC.concessionRanking.totalRanked}`
                  }}</span>
                </p>
              </CCardBody>
            </CCard>
            <CRow v-if="!showCompletionBlocks" class="justify-content-center">
              <Loader
                width="100px"
                height="100px"
                fill-color="#c2c2c2"
                cssClass="fadeIn anim-speed--1s"
              />
            </CRow>
          </CCol>
          <CCol xl="4">
            <CCard
              v-if="showCompletionBlocks"
              class="product-results fadeIn anim-speed--1s"
            >
              <CCardHeader class="bg-custom-box">
                <strong>{{
                  messages.products.accessories.toUpperCase()
                }}</strong>
              </CCardHeader>
              <CCardBody class="bg-custom-box">
                <RankingProgress
                  code="ACC"
                  :value="completion.ACC.value"
                  :rawValue="completion.ACC.rawValue"
                  :concept="completion.ACC.concept"
                  :criterion="completion.ACC.criterion"
                  :progressBarValues="completion.ACC.progressBarValues"
                />
              </CCardBody>
              <CCardHeader v-if="averageBlocks !== null" class="ranking"
                >Ranking</CCardHeader
              >
              <CCardBody v-if="averageBlocks !== null">
                <p v-if="showAverageBlocks >= 1">
                  <span class="product-results--desc"
                    >{{ averageBlocks.ACC.country.description }}
                  </span>
                  <span class="product-results--value">{{
                    `${averageBlocks.ACC.country.value} ${averageBlocks.ACC.country.units}`
                  }}</span>
                </p>
                <p v-if="showAverageBlocks >= 2">
                  <span class="product-results--desc"
                    >{{ averageBlocks.ACC.aspmRegional.description }}
                  </span>
                  <span class="product-results--value">{{
                    `${averageBlocks.ACC.aspmRegional.value}  ${averageBlocks.ACC.aspmRegional.units}`
                  }}</span>
                </p>
                <p v-if="showAverageBlocks === 3">
                  <span class="product-results--desc"
                    >{{ averageBlocks.ACC.concession.description }}
                  </span>
                  <span class="product-results--value">{{
                    `${averageBlocks.ACC.concession.value}  ${averageBlocks.ACC.concession.units}`
                  }}</span>
                </p>
                <p v-if="showAverageBlocks === 3">
                  <span
                    >{{ averageBlocks.ACC.concessionRanking.description }}
                  </span>
                  <span class="product-results--value">{{
                    `${averageBlocks.ACC.concessionRanking.ranking} de ${averageBlocks.ACC.concessionRanking.totalRanked}`
                  }}</span>
                </p>
              </CCardBody>
            </CCard>
            <CRow v-if="!showCompletionBlocks" class="justify-content-center">
              <Loader
                width="100px"
                height="100px"
                fill-color="#c2c2c2"
                cssClass="fadeIn anim-speed--1s"
              />
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Api from "@/services/Api";
import formatNum from "@/utils/formatNum";
import langStr from "@/utils/langStr";
const FiltersAreaCombo = () =>
  import(
    /* webpackChunkName: "filters-area-combo" */ "@/components/organisms/FiltersAreaCombo"
  );
const FilterFY = () =>
  import(/* webpackChunkName: "filter-fiscal-year" */ "@/components/molecules/FilterFY");
const FilterMonth = () =>
  import(/* webpackChunkName: "filter-month" */ "@/components/molecules/FilterMonth");
const FilterIsCumulative = () =>
  import(
    /* webpackChunkName: "filter-is-cumulative" */ "@/components/molecules/FilterIsCumulative"
  );

const Loader = () =>
  import(/* webpackChunkName: "loader" */ "@/components/atoms/Loader");

const RankingProgress = () =>
  import(
    /* webpackChunkName: "ranking-progress" */ "@/components/molecules/RankingProgress"
  );

import { CChartLine } from "@coreui/vue-chartjs";
import { CChartBar } from "@coreui/vue-chartjs";
import currentFY from "@/services/CurrentFY";
import filtersByProfile from "@/helpers/filtersByProfile";
import defaultAreaParam from "@/helpers/defaultAreaParam";
import formatFiscalYear from "@/helpers/formatFiscalYear";
import getAreaName from "@/helpers/getAreaName";

export default {
  name: "ControlPanel",
  components: {
    FiltersAreaCombo,
    FilterFY,
    FilterMonth,
    FilterIsCumulative,
    CChartLine,
    CChartBar,
    Loader,
    RankingProgress,
  },
  data() {
    return {
      filtersList: null,
      showBools: {
        FiltersAreaCombo: false,
        FilterFy: false,
        FilterMonth: false,
        FilterIsCumulative: false,
      },
      activeItems: ["countries", "regionals", "aspms", "concessions"],
      collapse: false,
      cardCollapse: true,
      isCollapsed: true,
      areaError: false,
      showResults: false,
      period: null,
      invoiceNoCumulative: {
        show: null,
        datasets: [],
        labels: [],
      },
      invoiceCumulative: {
        show: null,
        datasets: [],
        labels: [],
      },
      completion: {
        BV: {
          value: null,
          concept: null,
          position: 0,
          progressBarValues: {
            max: 150,
            first: null,
            second: null,
            third: null,
          },
        },
        T03: {
          value: null,
          concept: "€/UIO",
          position: 0,
          progressBarValues: {
            max: 15,
            first: 0,
            second: 0,
            third: 0,
          },
        },
        T05: {
          value: null,
          concept: "€/UIO",
          position: 0,
          progressBarValues: {
            max: 15,
            first: null,
            second: null,
            third: null,
          },
        },
        BPC: {
          value: null,
          concept: "€/UIO",
          position: 0,
          progressBarValues: {
            max: 15,
            first: null,
            second: null,
            third: null,
          },
        },
        ACC: {
          value: null,
          concept: null,
          position: 0,
          progressBarValues: {
            max: 150,
            first: null,
            second: null,
            third: null,
          },
        },
      },
      average: {
        country: null,
        regional: null,
        aspm: null,
      },
      currentAreaCategory: null,
      selectedFY: null,
      selectedMonth: null,
      averageBlocks: null,
      showAverageBlocks: 0,
      showCompletionBlocks: false,
      rankingCommonParams: null,
      selectedArea: null,
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getCurrentLang;
    },
    messages() {
      return this.$store.getters.getLangMessages;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
    // Refactor
    /**
     * Implicit parameters are those that are already known
     * based on the user's profile, so that not all filters have to be shown.
     */
    implicitParams() {
      const n = this.user.profileId;
      if (n >= 20 && n < 30) {
        return {
          concessionCode: this.user.concessionCode,
          countryCode: "",
          regionalCode: "",
          aspm: "",
        };
      }
      return null;
    },
  },
  methods: {
    /**
     * Define Request default parameters and call the query function
     */
    makeDefaultQueries() {
      // Show Results Card
      this.showResults = true;
      // Hide completion boxes
      this.showCompletionBlocks = false;

      // Initial Area Params
      // This process is used in ReferenceNumbers component (Refactor)
      /**
       * Promise function who receives the current fiscal year, then define other default parameters
       */
      currentFY().then((fiscalYear) => {
        // Get the value of the default area parameter via the helper defaultAreaParam
        const defaultArea = defaultAreaParam(this.user);
        const defaults = {};
        const areaKey = defaultArea.key;
        defaults.area = {
          countryCode: "",
          regionalCode: "",
          aspm: "",
          concessionCode: "",
        };
        defaults.area[areaKey] = defaultArea.value;
        defaults.fy = { fiscalYear: fiscalYear };
        const currentMonth = new Date().getMonth() + 1;
        defaults.month = { month: currentMonth };
        defaults.cumulative = { isCumulative: 0 };

        this.rankingCommonParams = {
          fiscalYear: fiscalYear,
          month: currentMonth,
          isCumulative: 0,
        };

        // Printing text variables for display
        this.printTextVariables(defaults.fy.fiscalYear, defaults.month.month);

        // Make API query with default values
        this.makeApiQueries(defaults);
      });
    },
    /**
     * Handling filter values and calling the query function
     */
    submitFilterValues(event) {
      event.preventDefault();

      // Variable to check required values
      let validated = false;
      // Define parameters
      const params = {
        area: { ...this.$store.getters.getFiltersAreaComboParams },
        fy: { fiscalYear: this.$store.getters.getFilterFYValue },
        month: { month: this.$store.getters.getFilterMonthValue },
        cumulative: {
          isCumulative: this.$store.getters.getFilterIsCumulativeValue,
        },
      };

      // Retrieves implicit parameters if any
      if (this.implicitParams) {
        params.area = { ...this.implicitParams };
        validated = true;
      } else {
        if (!this.$store.getters.getFiltersAreaComboParams) {
          // Show area filter validation error
          this.areaError = true;
        } else {
          // Hide area filter validation error
          this.areaError = false;
          validated = true;
        }
      }
      if (validated) {
        // Show Results Card
        this.showResults = true;
        // Hide completion boxes
        this.showCompletionBlocks = false;

        // Printing text variables for display
        this.selectedFY = this.$store.getters.getFilterFYValue;
        this.selectedMonth = this.$store.getters.getFilterMonthValue;
        this.printTextVariables(this.selectedFY, this.selectedMonth);

        // Make API query with filter values
        this.makeApiQueries(params);
      }
    },

    /**
     * Perform API queries for the different components
     */
    makeApiQueries(params) {
      this.selectedArea = getAreaName(params.area, this.messages)
      params.percentProducts = { productCodes: ["ACC", "BV"] };
      params.averageProducts = { productCodes: ["T03", "T05", "BPC"] };
      params.allProducts = {
        productCodes: ["ACC", "BV", "T03", "T05", "BPC"],
      };
      params.langId = { languageId: langStr.getId(this.lang) };
      // console.log("RECEIVED PARAMS", params);

      // Saving selected fiscal year for tables legends
      this.selectedFY = params.fy.fiscalYear;

      // AVERAGE REFERENCES
      // Printing of different types of ranking average references depending on the type of the selected area parameter
      const averageRefsParams = { ...params.area };

      // console.log("\naverageRefsParams >>>", averageRefsParams);
      this.printAverageReferences(averageRefsParams, params);

      // INVOICE
      const invoiceParams = {
        ...params.area,
        ...params.fy,
        ...params.month,
      };

      // console.log("\ninvoiceParams >>>", invoiceParams);
      // Query to get invoice data for no cumulative invoice data table
      this.getInvoiceData(invoiceParams, 0);

      // Query to get invoice data for cumulative invoice data table
      this.getInvoiceData(invoiceParams, 1);

      // PERCENTAGES
      // Query to get percent of completion data by product for Tooltip in RankingProgress component
      const productPercentParams = {
        ...params.area,
        ...params.fy,
        ...params.month,
        ...params.cumulative,
        ...params.percentProducts,
      };

      // console.log("\nproductPercentParams >>>", productPercentParams);
      this.getProductPercentData(productPercentParams).then((result) => {
        this.fillProductCompletionData(result, "percentageValue");
      });

      // AVERAGES
      // Query to get average of completion data by product for Tooltip in RankingProgress component
      const completionAverageParams = {
        ...params.area,
        ...params.fy,
        ...params.month,
        ...params.cumulative,
        ...params.averageProducts,
      };

      // console.log("\ncompletionAverageParams >>>", completionAverageParams);
      this.getProductAverageData(completionAverageParams).then((result) => {
        this.fillProductCompletionData(result, "average");
      });

      // COMPLETION CRITERIA
      // Query to get completion criteria for progress bar and position of tooltip in RankingProgress component
      const criteriaParams = {
        ...params.fy,
        ...params.month,
        ...params.allProducts,
        ...params.langId,
      };

      // console.log("\ncriteriaParams >>>", criteriaParams);
      this.getProductsCompletionCriterion(criteriaParams).then((result) =>
        this.processCriterionZones(result.data.data)
      );
    },
    // Get Area info for Concession or ASPM
    getAreaInfo(category, value) {
      const params = {};
      params[category] = value;

      return Api.sendPost("sys/searchAreaInfo", params).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            const areaInfo = { ...response.data.data };
            return areaInfo;
          }
        }
      });
    },
    // Get data for invoice tables
    getInvoiceData(params, cumulative) {
      params.isCumulative = cumulative;

      this.invoiceCumulative.show = false;
      this.invoiceNoCumulative.show = false;

      Api.sendPost("basicReport/controlPanel/invoice", params).then(
        (response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              if (cumulative) {
                this.processInvoiceCumulative(response.data.data);
                this.invoiceCumulative.show = true;
              } else {
                this.processInvoiceNoCumulative(response.data.data);
                this.invoiceNoCumulative.show = true;
              }
            }
          }
        }
      );
    },
    // Get data of completion percentage by product
    getProductPercentData(params) {
      return Api.sendPost("basicReport/controlPanel/completion", params).then(
        (response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              return response.data.data;
            }
          }
        }
      );
    },
    // Get data of completion average by product
    getProductAverageData(params) {
      return Api.sendPost(
        "basicReport/controlPanel/productAverage",
        params
      ).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            return response.data.data;
          }
        }
      });
    },
    // Get completion criteria (minimum and maximum) by product
    getProductsCompletionCriterion(params) {
      return Api.sendPost(
        "basicReport/controlPanel/completionCriterion",
        params
      );
    },
    // Get ranking information by concessions
    getProductAverageRankingData(params) {
      // console.log("product average ranking params", params);
      return Api.sendPost(
        "basicReport/controlPanel/productAverageRanking",
        params
      ).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            return response.data.data;
          }
        }
      });
    },
    // Process invoice data for invoice no cumulative table
    processInvoiceNoCumulative(data) {
      const labelsAxisX = [];
      const lastFYData = {
        label: `FY ${this.selectedFY - 1}`,
        backgroundColor: "rgba(0,0,0,0.1)",
        data: [],
      };
      const currentFYData = {
        label: `FY ${this.selectedFY}`,
        backgroundColor: "rgba(195,0, 47, 0.4)",
        data: [],
      };

      data.map((el) => {
        labelsAxisX.push(this.messages.filters.months[el.month]);
        lastFYData.data.push(el.lastFyPurchases);
        currentFYData.data.push(el.currentFyPurchases);
      });

      this.invoiceNoCumulative.datasets = [lastFYData, currentFYData];
      this.invoiceNoCumulative.labels = labelsAxisX;
      this.invoiceNoCumulative.show = true;
    },
    // Process invoice data for invoice cumulative table
    processInvoiceCumulative(data) {
      const labelsAxisX = [];
      const lastFYData = {
        label: `FY ${this.selectedFY - 1}`,
        backgroundColor: "rgba(0,0,0,0.3)",
        data: [],
      };
      const currentFYData = {
        label: `FY ${this.selectedFY}`,
        backgroundColor: "rgba(195,0, 47, 0.6)",
        data: [],
      };

      data.map((el) => {
        labelsAxisX.push(this.messages.filters.months[el.month]);
        lastFYData.data.push(el.lastFyPurchases);
        currentFYData.data.push(el.currentFyPurchases);
      });

      this.invoiceCumulative.datasets = [lastFYData, currentFYData];
      this.invoiceCumulative.labels = labelsAxisX;
    },
    // Fill completion data for tooltip of RankingProgress component
    fillProductCompletionData(results, type) {
      results.map((elem) => {
        const percent = type === "percentageValue" ? "%" : "";
        this.completion[elem.productCode].value = `${formatNum.get(
          elem[type],
          2
        )}${percent}`;
        this.completion[elem.productCode].rawValue = elem[type];
        // this.completion[elem.productCode].rawValue = 90;
        if (type === "percentageValue")
          this.completion[elem.productCode].concept =
            this.messages.ranking.completionAbbr;
      });
    },
    // Several text variables in layout
    printTextVariables(fy, month) {
      // console.log(":::::", fy, month);
      // this.selectedFY = this.$store.getters.getFilterFYValue;
      // this.selectedMonth = this.$store.getters.getFilterMonthValue;
      this.period = ` de ${this.messages.filters.months[4]} a ${this.messages.filters.months[month]} / ${formatFiscalYear(fy)}`;
    },
    // Print average references in concessions ranking information
    printAverageReferences(areaParams) {
      if (areaParams.countryCode !== "") {
        this.printRankings({ countryCode: areaParams.countryCode });
      }
      if (areaParams.regionalCode !== "") {
        this.getAreaInfo("regionalCode", areaParams.regionalCode).then(
          (areaInfo) =>
            this.printRankings(areaInfo, "regional", areaParams.regionalCode)
        );
      }
      if (areaParams.aspm !== "") {
        this.getAreaInfo("aspm", areaParams.aspm).then((areaInfo) =>
          this.printRankings(areaInfo, "aspm", areaParams.aspm)
        );
      }
      if (areaParams.concessionCode !== "") {
        this.getAreaInfo("concessionCode", areaParams.concessionCode).then(
          (areaInfo) => {
            this.printRankings(
              areaInfo,
              "concession",
              areaParams.concessionCode
            );
          }
        );
      }
    },
    /**
     * printRankings (areaInfo, category, category value);
     */
    printRankings(...parameters) {
      const areaInfo = parameters[0];
      const arrayProducts = ["BV", "T03", "T05", "BPC", "ACC"];
      let commonParams;
      if (!this.rankingCommonParams) {
        commonParams = {
          fiscalYear: this.$store.getters.getFilterFYValue,
          month: this.$store.getters.getFilterMonthValue,
          isCumulative: this.$store.getters.getFilterIsCumulativeValue,
          productCodes: arrayProducts,
        };
      } else {
        commonParams = {
          ...this.rankingCommonParams,
          productCodes: arrayProducts,
        };
        this.rankingCommonParams = null;
      }

      const countryParams = { ...commonParams };
      countryParams.countryCode = areaInfo.countryCode;

      let strAspmRegional, strConcession;

      const promises = [this.getProductAverageData(countryParams)];
      if (parameters.length > 1) {
        const category = parameters[1];
        const categoryValue = parameters[2];

        if (category !== "concession") {
          const newParams = { ...commonParams };
          let code;
          switch (category) {
            case "regional":
              strAspmRegional = this.messages.ranking.regional;
              code = "regionalCode";
              break;
            case "aspm":
              strAspmRegional = this.messages.ranking.aspm;
              code = "aspm";
              break;
          }

          newParams[code] = categoryValue;
          promises.push(this.getProductAverageData(newParams));
        }

        if (category === "concession") {
          const regionalParams = { ...commonParams };
          regionalParams.regionalCode = areaInfo.regionalCode;
          strAspmRegional = this.messages.ranking.regional;
          promises.push(this.getProductAverageData(regionalParams));

          const concessionParams = { ...commonParams };
          concessionParams.concessionCode = categoryValue;
          strConcession = this.messages.ranking.concession;
          promises.push(this.getProductAverageRankingData(concessionParams));
        }
        // console.log("promises", promises);
      }
      Promise.all(promises).then((results) => {
        let resultsCountry, resultsAspmRegional, resultsConcession;
        this.averageBlocks = {};
        const strUnits = (code) => (code === "ACC" ? "€/NCS" : "€/UIO");
        if (results.length >= 1 && results[0]) {
          resultsCountry = results[0];
          resultsCountry.map((elem) => {
            this.averageBlocks[elem.productCode] = {
              country: {
                description: this.messages.ranking.country,
                value: formatNum.get(elem.average, 2),
                units: strUnits(elem.productCode),
              },
            };
          });
        }

        if (results.length >= 2) {
          resultsAspmRegional = results[1];
          resultsAspmRegional.map((elem) => {
            this.averageBlocks[elem.productCode].aspmRegional = {
              description: strAspmRegional,
              value: formatNum.get(elem.average, 2),
              units: strUnits(elem.productCode),
            };
          });
        }

        if (results.length === 3) {
          resultsConcession = results[2];
          // console.log(resultsConcession);
          resultsConcession.map((elem) => {
            this.averageBlocks[elem.productCode].concession = {
              description: strConcession,
              value: formatNum.get(elem.average, 2),
              units: strUnits(elem.productCode),
            };
            this.averageBlocks[elem.productCode].concessionRanking = {
              description: this.messages.ranking.concession2,
              ranking: elem.ranking,
              totalRanked: elem.totalRanked,
            };
          });
        }

        // console.dir(this.averageBlocks);
        this.showCompletionBlocks = true;
        this.showAverageBlocks = promises.length;
      });
    },
    // Refactor this function is reused in @/components/organisms/CompletionCard.vue
    processCriterionZones(products) {
      products.map((product) => {
        const obj = this.completion[product.productCode];
        const min = Math.round(product.minValue);
        const max = Math.round(product.maxValue);
        const total = obj.progressBarValues.max;
        obj.criterion = {
          min: Math.round(product.minValue),
          max: Math.round(product.maxValue),
        };
        obj.progressBarValues.first = Math.round(min);
        obj.progressBarValues.second = Math.round(max - min);
        obj.progressBarValues.third = Math.round(total - max);
        let positions, zoneLimits;
        if (product.productCode !== "BV" && product.productCode !== "ACC") {
          zoneLimits = {
            first: Math.round(min),
            second: Math.round(max - min),
            third: Math.round(total - max),
          };
          positions = {
            positionMin: min / (total / 100),
            positionMax: max / (total / 100),
          };
        } else {
          const fakeMin = min - min * 0.1;
          const fakeMax = max + max * 0.1 - fakeMin;
          zoneLimits = {
            first: Math.round(fakeMin),
            second: Math.round(fakeMax),
            third: Math.round(total - (fakeMin + fakeMax)),
          };
          positions = {
            positionMin: fakeMin / (total / 100),
            positionMax: (max + max * 0.1) / (total / 100),
          };
        }

        obj.progressBarValues = {
          max: total,
          ...zoneLimits,
          legends: {
            min: min,
            max: max,
            total: total,
            ...positions,
          },
        };
      });
    },
    mountFilters(filtersNames) {
      filtersNames.forEach((elem) => {
        if (typeof elem === "string") {
          this.showBools[elem] = true;
        } else if (typeof elem === "object") {
          this.showBools.FiltersAreaCombo = true;
          this.activeItems = elem.FiltersAreaCombo;
          // console.log(this.activeItems);
        }
      });
    },
  },
  created() {
    this.makeDefaultQueries();
    this.filtersList = filtersByProfile("controlPanel", this.user.profileId);
    // console.log(this.filtersList);
    this.mountFilters(this.filtersList);
  },
};
</script>

<style lang="scss" scoped>
.bg-custom-box {
  background: linear-gradient(90deg, #e3e3e3, #ffffff);
  border-bottom: 0;
}
/* .col-xl-2 {
  padding-right: 4px;
  padding-left: 4px;
} */
.product-results {
  .card-header,
  .card-body {
    padding: 0.375rem 0.75rem;
  }

  .card-body {
    &.bg-custom-box {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  .card-header {
    &.ranking {
      border-top: 1px solid #d8dbe0;
    }
  }
  p {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 0.75rem;
    line-height: 1rem;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;

    .product-results {
      &--desc {
        padding-right: 1rem;
      }
      &--value {
        font-weight: 700;
        white-space: nowrap;
      }
    }
  }
}
</style>
